
<template>
  <v-container class="pa-0 pa-md-12">
    <v-row no-gutters>
      <v-spacer class="d-none d-sm-flex"></v-spacer>
      <v-col xs="12" sm="12" md="12" lg="8" mx-auto>
        <v-stepper v-model="stepperStep" vertical>
          <h1
            class="text-lg-h3 text-xs-h6 font-weight-medium pa-4 text-xs-center"
          >Transport prijs berekenen</h1>

          <v-stepper-step :complete="stepperStep > 1" step="1" color="red">
            Goederen informatie
            <small>Wat wilt u vervoeren?</small>
          </v-stepper-step>
          <v-stepper-content step="1" color="red">
            <CargoForm @submitCargoForm="submitCargoForm" />
          </v-stepper-content>

          <v-stepper-step :complete="stepperStep > 2" step="2" color="red">
            Transport gegevens
            <small>Start en eind bestemming</small>
          </v-stepper-step>
          <v-stepper-content step="2">
            <TransportRouteForm @submitRoute="submitRoute" @goToPreviousStep="goToPreviousStep" />
          </v-stepper-content>

          <v-stepper-step :complete="stepperStep > 3" step="3" color="red">Laad en los tijden</v-stepper-step>
          <v-stepper-content step="3">
            <TimeForm @submitTimeForm="submitTimeForm" @goToPreviousStep="goToPreviousStep" />
          </v-stepper-content>
          <v-stepper-step step="4" color="red">
            Reserveren
            <small>Zie de prijs van uw transport en boek gelijk</small>
          </v-stepper-step>
          <v-stepper-content step="4">
            <ReservationForm
              @submitReservation="submitReservation"
              :price="price"
              :extremeWeight="reservation.cargo.extremeWeight"
              :extremeSize="reservation.cargo.extremeSize"
              :difficultTransport="reservation.cargo.difficultTransport"
              :sendingRequest="sendingRequest"
              @goToPreviousStep="goToPreviousStep"
            />
          </v-stepper-content>
        </v-stepper>
      </v-col>
      <v-spacer class="d-none d-sm-flex"></v-spacer>
    </v-row>
  </v-container>
</template>

<script>
import CargoForm from "../components/CargoForm";
import TransportRouteForm from "../components/TransportRouteForm";
import TimeForm from "../components/TimeForm";
import ReservationForm from "../components/ReservationForm";
import axios from "axios";

export default {
  components: {
    CargoForm,
    TransportRouteForm,
    TimeForm,
    ReservationForm,
  },

  data() {
    return {
      stepperStep: 1,
      cargoValid: true,
      reservation: {
        cargo: {
          extremeWeight: false,
          extremeSize: false,
          difficultTransport: false,
        },
      },
      price: 0,
      sendingRequest: false,
    };
  },

  methods: {
    goToPreviousStep() {
      if (this.stepperStep > 1) {
        this.stepperStep = this.stepperStep - 1;
      }
    },
    submitCargoForm(cargo) {
      this.reservation.cargo = cargo;
      this.stepperStep = 2;
    },
    submitRoute(route) {
      this.reservation.route = route;
      this.stepperStep = 3;
    },
    submitTimeForm(loadingTimes) {
      this.reservation.loadingTimes = loadingTimes;
      this.calculatePrice();
      this.stepperStep = 4;
    },
    submitReservation(reservation) {
      this.reservation.clientInfo = reservation;
      var params = {
        cargoType: this.reservation.cargo.type,
        cargoDescription: this.reservation.cargo.name,
        cargoBrand: this.reservation.cargo.brand,
        cargoModel: this.reservation.cargo.model,
        cargoWeight: this.reservation.cargo.weight,
        cargoHeight: this.reservation.cargo.size.height,
        cargoLength: this.reservation.cargo.size.length,
        cargoWidth: this.reservation.cargo.size.width,
        cargoIsDifficultToTransport: this.reservation.cargo.difficultTransport,
        //attachments: this.reservation.cargo.files,
        startLocation: this.reservation.route.startLocation.place_name_nl,
        endLocation: this.reservation.route.endLocation.place_name_nl,
        loadingTime: this.reservation.loadingTimes.loadingTime,
        unloadingTime: this.reservation.loadingTimes.unloadingTime,
        name: this.reservation.clientInfo.name,
        companyName: this.reservation.clientInfo.companyName,
        email: this.reservation.clientInfo.email,
        phone: this.reservation.clientInfo.phone,
        address: this.reservation.clientInfo.address.place_name_nl,
        postalCode: this.reservation.clientInfo.postalCode,
        desiredTransportDate: this.reservation.clientInfo.preferedDate,
        estimatedPrice: this.reservation.clientInfo.price,
      };
      this.sendEmail(params, this.reservation.cargo.files);
    },
    calculatePrice() {
      this.price = this.round(
        this.reservation.route.distance * 1.2 +
          this.reservation.loadingTimes.loadingTime +
          this.reservation.loadingTimes.unloadingTime,
        2
      );
    },
    round(value, decimals) {
      return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
    },
    sendEmail(params, files) {
      const formData = new FormData();
      
      files.forEach(file => {
        formData.append("attachments", file);
      })

      Object.keys(params).forEach((key) => formData.append(key, params[key]));
      this.sendingRequest = true;
      axios({
        method: "post",
        url: "https://jansenverhuurapi.nl/api/construction-equipment-transport-requests",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(() => {
          this.$router.push("/reservationSuccess");
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.sendingRequest = false;
        });
    },
  },
};
</script>

<style>
</style>