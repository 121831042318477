<template>
  <div>
    <v-card color="grey lighten-3" class="mb-12">
      <v-container>
        <v-form ref="form">
          <location-search @change="onStartLocationChange" label="Ophaaladres"></location-search>
          <location-search @change="onEndLocationChange" label="Afleveradres"></location-search>
        </v-form>
        <div class="grey--text px-md-9 pt-4">
          <p class="text-body-2">
            <span class="font-weight-bold">Let op!</span>
            <br />Wij verwachten dat de ingevulde locaties goedbereikbaar zijn met onze vrachtwagens.
            <br />Is dit niet het geval dan kan het zijn dat er een meer prijs wordt toegevoegd boven op de al berekende prijs.
          </p>
        </div>
      </v-container>
    </v-card>
    <v-btn color="red" text @click="$emit('goToPreviousStep')" class="mr-2">Vorige</v-btn>
    <v-btn color="red" dark @click="calculateDistance" :loading="loadingDistance">Volgende</v-btn>
  </div>
</template>

<script>
import axios from "axios";
import debounce from "debounce";
import LocationSearch from "./shared/LocationSearch.vue";

// 51.318090,5.367440 Lat long hoek 54 bergeijk
export default {
  name: "TransportRouteForm",
  data() {
    return {
      endLocation: null,
      startLocation: null,
      distance: 0,
      loadingDistance: false,
      jansenVerhuurLocatie: { lat: "51.318090", lon: "5.367440" },
      required: [(v) => !!v || "Dit veld is verplicht!"],
      route: {},
    };
  },
  components: {
    LocationSearch,
  },
  methods: {
    onStartLocationChange(startLocation) {
      this.startLocation = startLocation;
    },
    onEndLocationChange(endLocation){
      this.endLocation = endLocation;
    },
    calculateDistance() {
      var coordinates =
        this.jansenVerhuurLocatie.lon +
        "," +
        this.jansenVerhuurLocatie.lat +
        ";" +
        this.startLocation.center[0] +
        "," +
        this.startLocation.center[1] +
        ";" +
        this.endLocation.center[0] +
        "," +
        this.endLocation.center[1] +
        ";" +
        this.jansenVerhuurLocatie.lon +
        "," +
        this.jansenVerhuurLocatie.lat;
      this.loadingDistance = true;
      axios
        .get(
          "https://eu1.locationiq.com/v1/directions/driving/" +
            coordinates +
            "?key=d48cdffaffc4f0&steps=true"
        )
        .then((result) => {
          this.route.distance = parseFloat(result.data.routes[0].distance) / 1000;
          this.route.startLocation = this.startLocation;
          this.route.endLocation = this.endLocation;
          this.loadingDistance = false;
          this.submit();
        })
        .finally(() => (this.loadingDistance = false));
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.$emit("submitRoute", this.route);
      }
    },
  },
  watch: {
    searchEnd(value) {
      if (!value) {
        return;
      }
      // Debounce the input and wait for a pause of at
      // least 200 milliseconds. This can be changed to
      // suit your needs.
      debounce(this.makeSearchEnd, 200)(value, this);
    },
    searchStart(value) {
      if (!value) {
        return;
      }
      // Debounce the input and wait for a pause of at
      // least 200 milliseconds. This can be changed to
      // suit your needs.
      debounce(this.makeSearchStart, 200)(value, this);
    },
  },
};
</script>

<style>
</style>