<template>
  <v-autocomplete
    @change="$emit('change',address)"
    v-model="address"
    :items="locations"
    :rules="[rules.required]"
    :loading="searchingLocation"
    :search-input.sync="adressSearchTerm"
    no-filter
    item-text="place_name_nl"
    item-value="id"
    :label="getLabel"
    prepend-inner-icon="mdi-map-marker"
    hide-no-data
    color="black"
    required
    clearable
    return-object
    hint="Bijvoorbeeld: Hoek 54 Bergeijk"
  ></v-autocomplete>
</template>

<script>
import axios from "axios";

export default {
  name: "LocationSearch",
  props:{
    label: String,
  },
  data() {
    return {
      address: "",
      adressSearchTerm: "",
      searchingLocation: false,
      locations: [],
      accessToken:
        "pk.eyJ1Ijoiam9lcHZlcmhvZXZlbiIsImEiOiJja2UxaGpxeDg0NHV0MndtcTRjcDhqcjZiIn0.9mnih2kMWGcp_WhtJa4avw",
      rules: {
        required: (value) => !!value || "Dit veld is verplicht!",
      },
    };
  },
  computed: {
    getLabel() {
      if(this.label){
        return this.label;
      }
      else{
        return "Adres";
      }
    }
  },
  methods: {
    searchLocation() {
      this.searchingLocation = true;
      this.locations = [];
      axios
        .get(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURI(
            this.adressSearchTerm
          )}.json?language=nl&access_token=${this.accessToken}`
        )
        .then((result) => {
          result.data.features.forEach((element) => {
            this.locations.push(element);
          });
        })
        .finally(() => {
          this.searchingLocation = false;
        });
    },
  },
  watch: {
    adressSearchTerm(value) {
      if (!value) {
        return;
      }

      this.searchLocation();
    },
  },
};
</script>

<style>
</style>